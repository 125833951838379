<template>
  <v-card class="rounded-t-0" flat :loading="loading" v-if="weather">
    <v-list dense>
      <v-list-item v-if="weather.current && weather.current.temperature">
        <ListIcon><v-icon>mdi-thermometer</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-subtitle> Temperatur </v-list-item-subtitle>
          <v-list-item-title
            >{{ weather.current.temperature.value.toFixed(1) }}
            {{ weather.current.temperature.units }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="weather.current && weather.current.humidity">
        <ListIcon><v-icon>mdi-water</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-subtitle> Feuchtigkeit </v-list-item-subtitle>
          <v-list-item-title
            >{{ weather.current.humidity.value.toFixed(1) }}
            {{ weather.current.humidity.units }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="weather.current && weather.current.barometer">
        <ListIcon><v-icon>mdi-balloon</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-subtitle> Luftdruck </v-list-item-subtitle>
          <v-list-item-title
            >{{ weather.current.barometer.value.toFixed(1) }}
            {{ weather.current.barometer.units }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="weather.current && weather.current['wind speed']">
        <ListIcon><v-icon>mdi-wind-turbine</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-subtitle> Wind/Böen </v-list-item-subtitle>
          <v-list-item-title>
            <span v-if="weather.current['wind direction']"
              >{{ windDirection }}:</span
            >
            {{ weather.current["wind speed"].value.toFixed(1) }}/{{
              weather.current["wind gust"].value.toFixed(1)
            }}
            {{ weather.current["wind speed"].units }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="weather.current && weather.current['rain rate']">
        <ListIcon><v-icon>mdi-weather-rainy</v-icon></ListIcon>
        <v-list-item-content>
          <v-list-item-subtitle> Regen </v-list-item-subtitle>
          <v-list-item-title>
            {{ weather.current["rain rate"].value.toFixed(1) }}
            {{ weather.current["rain rate"].units }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text class="text-right text-caption py-0">
      <DateVue :value="date" long />, {{ time }}
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <v-btn
        text
        block
        small
        href="https://wetter.gymkirchenfeld.ch"
        target="_blank"
        >zur Wetterstation</v-btn
      ></v-card-actions
    >
  </v-card>
</template>
<script>
function degToCard(value) {
  value = parseFloat(value);
  if (value <= 11.25) return "N";
  value -= 11.25;
  var allDirections = [
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
    "N",
  ];
  var dIndex = parseInt(value / 22.5);
  return allDirections[dIndex] ? allDirections[dIndex] : "N";
}

import DateVue from "common/components/DateValue.vue";

export default {
  name: "Weather",
  components: { DateVue },
  data() {
    return {
      loading: false,
      weather: null,
    };
  },
  computed: {
    date() {
      if (
        this.weather &&
        this.weather.generation &&
        this.weather.generation.time
      ) {
        const time = this.weather.generation.time.replace(" CEST", "");
        const date = new Date(time);

        return date.toJSON().split("T")[0];
      }
      return "";
    },
    time() {
      if (
        this.weather &&
        this.weather.generation &&
        this.weather.generation.time
      ) {
        const time = this.weather.generation.time.replace(" CEST", "");
        const date = new Date(time);
        return date.toLocaleTimeString("de-CH").substring(0, 5);
      }
      return "";
    },
    windDirection() {
      return degToCard(this.weather.current["wind direction"].value);
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const response = await fetch(
          "https://wetter.gymkirchenfeld.ch/current_minimal.json"
        );
        this.weather = await response.json();
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    simulateData() {
      this.weather = {
        station: {
          location: "Gymnasium Kirchenfeld, Bern",
          latitude: 46.940556,
          longitude: 7.449722,
          "altitude (meters)": 531.874678030303,
          link: "",
        },
        generation: {
          time: "Mon, 12 Sep 2022 09:00:00 CEST",
          generator: "weewx 4.8.0",
        },
        current: {
          temperature: {
            value: 13.666666666666668,
            units: "°C",
          },
          humidity: {
            value: 84,
            units: "%",
          },
          barometer: {
            value: 1016.3566781056037,
            units: "mbar",
          },
          "wind speed": {
            value: 0,
            units: "km/h",
          },
          "wind gust": {
            value: 4.828032,
            units: "km/h",
          },
          "rain rate": {
            value: 0,
            units: "cm/h",
          },
          void_end: null,
        },
      };
    },
  },
  async created() {
    await this.fetchData();
    //this.simulateData();

    this.$emit("loaded");
  },
};
</script>
